declare let moment: any;

class ProfileBasicsPersonalController {
  private countries;
  private currentDate;
  private activeProfile;

  constructor(CountryService) {
    this.countries = CountryService.fetch();

    this.queryCountriesMemoizedWorker = _.memoize(
      this.queryCountriesWorker.bind(this),
      (params) => {
        return params.query + '|' + params.exclude.map((country) => country.id).join(',');
      },
    );
  }

  $onInit() {
    this.currentDate = moment().toDate();
  }

  $onChanges(params) {
    if (params.activeProfile && params.activeProfile.currentValue) {
      _.merge(params.activeProfile.currentValue.profile, { passports: [] });
    }
  }

  queryCountries(query, exclude) {
    if (this.countries.length) {
      return this.queryCountriesMemoizedWorker({ query, exclude });
    }

    return this.countries.$promise.then(() => {
      return this.queryCountriesMemoizedWorker({ query, exclude });
    });
  }

  private queryCountriesMemoizedWorker;
  private queryCountriesWorker({ query, exclude }) {
    exclude = exclude.map((country) => country.id);
    query = query.toLocaleLowerCase();

    return this.countries.filter((country) => {
      return (
        !exclude.includes(country.id) && country.name.toLocaleLowerCase().indexOf(query) !== -1
      );
    });
  }

  get profileNations() {
    if (!this.activeProfile) {
      return null;
    }

    const countries = [
      this.activeProfile.profile.country,
      this.activeProfile.profile.secondaryCountry,
      ...(this.activeProfile.profile.otherCountries || []),
    ];

    return countries.filter((item) => !!item);
  }
}

angular.module('app.profile').component('profileBasicsPersonal', {
  templateUrl: 'profile/components/basics/personal.html',
  controller: ProfileBasicsPersonalController,

  bindings: {
    editable: '<',
    activeProfile: '<',
  },
});
