import { LanguageService } from 'general/services/LanguageService';

class ProfileBasicsLanguagesController {
  private $languageService: LanguageService;

  private userProfile;

  constructor(LanguageService: LanguageService) {
    this.$languageService = LanguageService;
  }

  $onChanges(params) {
    if (params.userProfile && params.userProfile.currentValue) {
      _.merge(params.userProfile.currentValue, { languages: [] });
    }
  }

  get availableLanguages() {
    return this.$languageService.languages.filter((_language) => true);
  }

  get availableLevels() {
    return this.$languageService.levels;
  }

  addLanguage() {
    this.userProfile.languages.push({});
  }

  removeLanguage(language) {
    const index = this.userProfile.languages.indexOf(language);

    this.userProfile.languages.splice(index, 1);
  }
}

angular.module('app.profile').component('profileBasicsLanguages', {
  templateUrl: 'profile/components/basics/languages.html',
  controller: ProfileBasicsLanguagesController,

  bindings: {
    editable: '<',
    userProfile: '<',
  },
});
