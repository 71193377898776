import { Representation } from '../../../profile/services/RepresentationService';

class ProfileAgentSelectorController {
  private $representationService;

  private promise: Promise<any>;
  private value: Representation;
  private onSelect: (params: { $value: Representation }) => void;
  private onDelete: (params: { $value: Representation }) => void;

  constructor(RepresentationService) {
    this.$representationService = RepresentationService;
  }

  async search(query: string) {
    let found = [];

    if (query) {
      found = await this.$representationService.queryAvailableAgents(query).$promise;
    }

    return [
      this.$representationService.wrap({ type: 'self-represented' }),
      this.$representationService.wrap({ type: 'family-represented' }),
      ...found,
    ];
  }

  remove() {
    this.onDelete({ $value: this.value });
  }

  select(value: Representation) {
    this.onSelect({ $value: value });
  }

  confirm() {
    this.promise = this.$representationService
      .confirmRepresentations([this.value._id])
      .then(() => (this.value.confirmed = true));
  }
}

angular.module('app.profile').component('profileAgentSelector', {
  templateUrl: 'profile/components/agents/agent-selector.html',
  controller: ProfileAgentSelectorController,

  bindings: {
    value: '<',
    onSelect: '&',
    onDelete: '&',
  },
});
